<template>
  <div class="chessHand" style="padding-bottom: 20px">
    <div v-title data-title="老会员专享日"></div>

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-oldmember.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>指定会员:</p>
              <span style="color:var(--li-colortext);">VIP3以上会员</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">{{ datalist.act_time }}</span>
            </div>
            <div class="title-item">
              <p>领取时间:</p>
              <span style="color:var(--li-colortext);">{{ datalist.act_time_cn }}</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">实名注册≥60天正式账号，且VIP3以上会员。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="oldmember-box">
          <div class="membertop">
            <div class="membertop-left">
              奖励明细
            </div>
            <div class="membertop-right">
              <p>当前等级：<span>{{ datalist.now_level }}</span></p>
              <p>当前存款：<span>{{ datalist.money }}</span></p>
              <p>您已加入贝宝<span>{{ datalist.tian }}</span>天</p>
            </div>
          </div>
          <div class="membercenter">
            <div class="membercenter-box1">
              <div class="membercenter-vip">{{ datalist['level_xq'][0][0] }}</div>
              <div class="membercenter-vip">{{ datalist['level_xq'][1][0] }}</div>
              <div class="membercenter-vip">{{ datalist['level_xq'][2][0] }}</div>
            </div>

            <div class="membercenter-box2">
              <div class="membercenter-title membercenter-title2">限时存款</div>
              <div class="membercenter-text" style="background-color:#ebf5fd;">{{ datalist['level_xq'][0][1] }}</div>
              <div class="membercenter-text" style="background-color:#ebf5fd;">{{ datalist['level_xq'][1][1] }}</div>
              <div class="membercenter-text" style="background-color:#ebf5fd;">{{ datalist['level_xq'][2][1] }}</div>
              <div class="membercenter-title">实际到账</div>
              <div class="membercenter-title" style="font-weight: 400;">{{ datalist['level_zj'][0] }}</div>
            </div>
            <div class="membercenter-box3">
              <div class="membercenter-title membercenter-title2">限时存款</div>
              <div class="membercenter-text" style="background-color:#fef3ea;">{{ datalist['level_xq'][0][2] }}</div>
              <div class="membercenter-text" style="background-color:#fef3ea;">{{ datalist['level_xq'][1][2] }}</div>
              <div class="membercenter-text" style="background-color:#fef3ea;">{{ datalist['level_xq'][2][2] }}</div>
              <div class="membercenter-title">实际到账</div>
              <div class="membercenter-title" style="font-weight: 400;">{{ datalist['level_zj'][1] }}</div>
            </div>
            <div class="membercenter-box4">
              <div class="membercenter-title membercenter-title2">限时存款</div>
              <div class="membercenter-text" style="background-color:#4d507c;">{{ datalist['level_xq'][0][3] }}</div>
              <div class="membercenter-text" style="background-color:#4d507c;">{{ datalist['level_xq'][1][3] }}</div>
              <div class="membercenter-text" style="background-color:#4d507c;">{{ datalist['level_xq'][2][3] }}</div>
              <div class="membercenter-title">实际到账</div>
              <div class="membercenter-title" style=" color: #FFC887 !important;font-weight: 400;">
                {{ datalist['level_zj'][2] }}</div>
            </div>
          </div>
          <div class="memberbottom">
            <div class="memberbottom-text">
              <p>*申请条件：</p>
              <p>VIP≥3，实名注册时间≥60天，即可申请领取。</p>
            </div>
            <div class="btn-box">
              <div @click="applyWay" style="margin-bottom:6px;margin-top: 10px;">申请领取</div>
            </div>
          </div>
        </div>

        <!-- 申请记录 -->
        <div class="rule-content" style="margin-bottom: 30px;">
          <div class="rule-content-title4" style="margin-top: 25px;"></div>
          <p style="font-size: 14px;text-align: center;margin-top: 15px;color: var(--td-colortext);">仅展示近10笔，最长保留30天</p>

          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  时间
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  红利
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  状态
                </th>
              </tr>
            </thead>
            <tbody v-if="datalist.apply_data">
              <tr v-for="(item, index) in datalist.apply_data" :key="index">
                <td>{{ item.created_at }}</td>
                <td>{{ item.bouns_amout }}</td>
                <td>{{ item.status == 0 ? '审核中' : item.remark }}</td>
              </tr>
            </tbody>
          </table>


        </div>


        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                VIP3以上会员，实名注册≥60天正式账号才能参与领取。
              </li>
              <li>
                <span>2.</span>
                此活动由于需要固定的存款金额，因此不支持虚拟币充值领取。
              </li>
              <li>
                <span>3.</span>
                本平台凡是需要本金申请的优惠活动每天只能领取一个。
              </li>
              <li style="color: red;">
                <span>4.</span>
                本月必须有≥8次存款方可参与活动。
              </li>
              <li v-if="datalist">
                <span>5.</span>
                获得的彩金将{{datalist.level_limit}}倍流水方可提款。
              </li>
              <li>
                <span>6.</span>
                限时活动的存款时间以实际到账时间为准。限每月8日、18日、28日当天存款的单笔指定金额参与活动。
              </li>
              <li>
                <span>7.</span>
                每个会员在会员日当天仅享受一次指定金额存款折扣。
              </li>
              <li>
                <span>8.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>9.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>10.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>11.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>12.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <!-- <van-popup v-model="showPicker" round position="bottom">
      <van-picker title="请选择场馆" show-toolbar value-key="name" :columns="datalist.youxi" @confirm="confirm"
        @cancel="cancel" />
    </van-popup> -->
  </div>
</template>

<script>
import { getdata } from '../network/api'
export default {
  data () {
    return {
      datalist: {
        level_xq: [
          ["V3-5", "￥1900", "￥4750", "￥9600"],
          ["V6-8", "￥1850", "￥4700", "￥9300"],
          ["V9-10", "￥1800", "￥4500", "￥9000"]
        ],
        level_zj: [2000, 5000, 10000],
        money: 0,
        now_level: '无',
        tian: 0,
        act_time: '',
        act_time_cn: ''
      },
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
      showPicker: false,
    }
  },
  onShow () { },

  mounted () {
    let that = this
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'old_member_only') {
          if (e.data.status == 1) {
            that.getdata()
          }
        }
      })
    }
  },

  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata () {
      var _this = this
      var data = '?type=old_member_only'
      getdata(data).then((res) => {
        this.datalist = res.message
        _this.shuaxin()
      })
    },

    shuaxin () {
      let that = this
      window.FunctionUniapp = function (arg) {
        if (JSON.stringify(arg) == 1) {
          that.getdata()
        }
      }
    },
    changeisshow () {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay () {
      uni.getEnv((res) => {
        
        if (Object.keys(res)[0] == 'h5') {
          console.log("触发oldmember")
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'old_member_only'
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
          // Toast('该活动仅在app开发,请下载APP')
        } else {
          // 申请
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'old_member_only',
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'vipActapply',
              actname: 'old_member_only'
            },
          })
        }
      })
    },
    confirm (e) {
      uni.postMessage({
        data: {
          urlApply: 'vipActapply',
          actname: 'ChessxuBonus',
          gameid: e.id
        }
      })
      this.showPicker = false
    },
    cancel () {
      this.showPicker = false
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    >div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      >div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}

.oldmember-box {
  margin: 10px 15px;
  // padding: 0 10px;
  box-shadow: 0px 3px 6px 0px rgba(31, 75, 119, 0.43);
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;

  .membertop {
    display: flex;
    background-color: #B9DDFF;

    .membertop-left {
      padding: 10px;
      width: 47%;
      min-height: 80px;
      line-height: 60px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #4f607c;
      font-size: 18px;
      letter-spacing: 2px;
      position: relative;
      background: linear-gradient(180deg, #D3ECFF, #FFFFFF);
      border-top-right-radius: 10px;

      &::after {
        position: absolute;
        left: 0;
        top: 10%;
        content: '奖励明细';
        font-weight: 600;
        color: rgba(31, 75, 119, 0.03);
        font-size: 30px;
        letter-spacing: 2px;

      }
    }

    .membertop-right {
      padding: 10px 0;
      width: 53%;
      font-size: 13px;
      color: #4D5E7A;
      height: 100%;
      text-shadow: 0 0px 0.5px #4D5E7A;
      padding-left: 10px;
      padding-bottom: 0;

      p {
        margin-bottom: 6px;
      }

      span {
        color: #418BEB !important;
        font-size: 13px;
      }
    }
  }

  .membercenter {
    display: flex;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
    justify-content: center;

    .membercenter-title {
      font-family: PingFang SC;
      font-weight: bold;
      color: #4D5E7A;
      text-align: center;
      padding-bottom: 10px;
      font-size: 14px;
    }

    .membercenter-title2 {
      padding-top: 10px;
      padding-bottom: 0;
    }

    .membercenter-text {
      min-height: 45px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin: 10px 5px;
      color: #4D5E7A;
      // font-weight: 600;
      font-size: 14px;
    }

    .membercenter-box1 {
      width: 20%;

      .membercenter-vip {
        background: #b6dfff;
        color: #4D5E7A;
        padding: 10px 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        &:nth-child(1) {
          margin-top: 40px;
        }

        &:nth-child(2) {
          margin-top: 18px;
        }

        &:nth-child(3) {
          margin-top: 18px;
        }
      }
    }

    .membercenter-box2 {
      margin-right: 10px;
      width: 23%;
      box-shadow: 0px 3px 6px 0px rgba(31, 75, 119, 0.43);
      border-radius: 8px;
      background-color: #b6dfff;
    }

    .membercenter-box3 {
      margin-right: 10px;

      width: 23%;
      box-shadow: 0px 3px 6px 0px rgba(31, 75, 119, 0.43);
      border-radius: 8px;
      background-color: #f7d9b5;
    }

    .membercenter-box4 {
      width: 23%;
      box-shadow: 0px 3px 6px 0px rgba(31, 75, 119, 0.43);
      border-radius: 8px;
      background-color: #3b3e67;

      .membercenter-title {
        color: #fff !important;
      }

      .membercenter-text {
        color: #FFC887 !important;
      }
    }
  }

  .memberbottom {
    margin-top: 15px;
    padding: 0 10px;

    .memberbottom-text {
      p {
        color: #1F4B77;
        font-size: 14px;
        font-family: PingFang SC;
        text-shadow: 0 0px 0.5px #1F4B77;

        &:nth-child(1) {
          letter-spacing: 1px;
          margin-bottom: 8px;
        }

        &:nth-child(2) {
          font-size: 12px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
